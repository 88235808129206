import { LanguageLevel, LocationDB, OfferSummary } from '@asgard/common-dto';
import { Offer } from 'interfaces/offer.interface';
import { UIOfferFooter } from 'interfaces/ui/ui-ofer-footer';
import { UIOfferBadge } from 'interfaces/ui/ui-offer-badge';
import { UIOfferBottomBar } from 'interfaces/ui/ui-offer-bottombar';
import { UIOfferData } from 'interfaces/ui/ui-offer-data';
import { UIOfferFaqsSection } from 'interfaces/ui/ui-offer-faqs-section';
import { UIOfferHeroSection } from 'interfaces/ui/ui-offer-hero-section';
import { UIOfferHowSection } from 'interfaces/ui/ui-offer-how-section';
import { UIOfferOfferSection } from 'interfaces/ui/ui-offer-offer-section';
import { UIOfferPerk } from 'interfaces/ui/ui-offer-perk';
import { UIOfferRequirementsSection } from 'interfaces/ui/ui-offer-requirements-section';
import { UIOfferSkill } from 'interfaces/ui/ui-offer-skill';
import { UIOfferSkillsSection } from 'interfaces/ui/ui-offer-skills-section';
import { UIOfferTeamSection } from 'interfaces/ui/ui-offer-team-section';
import {
  UIOfferRanges,
  UIOfferWhenSchedule
} from 'interfaces/ui/ui-offer-when-schedule';
import { UIOfferWhenSection } from 'interfaces/ui/ui-offer-when-section';
import { UI_WHERE_PERKS } from 'interfaces/ui/ui-offer-where-perk';
import { UIOfferWhereSection } from 'interfaces/ui/ui-offer-where-section';
import { Location } from 'model';
import { i18n } from 'next-i18next';
import { getTranslatedText } from 'services/multilingual.service';
import {
  OFFER_SOFT_SKILLS_SECTION,
  OFFER_STATUS_CLOSED,
  OFFER_TECH_SKILLS_LEVEL,
  OFFER_TECH_SKILLS_SECTION
} from 'services/offers.service';
import { CommonDtoLevelToLanguageLevelDict } from 'services/service.language.normalizer';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getTranslation } from 'utils/i18n';
import { getPublicPath } from 'utils/paths';
import { getMinutesFromStringTime, getStringTimeFromMinutes } from 'utils/time';
import { getKSalary } from './salary.service';

const UITechSkillsLevel: { [key in OFFER_TECH_SKILLS_LEVEL]: number } = {
  ADVANCED: 3,
  INTERMEDIATE: 2,
  BASIC: 1
};

/**
 * Converts a server-side `Offer` model into UI suitable structured data, according to the given `locale`
 *
 * @param offer - (server-side model) to be converted
 * @param locale -  in which `Multilingual` server-side data will be retrieved
 * @returns Data structure for the UI with the info from the `offer`
 */
export function getOfferUIData(offer: Offer, locale?: string): UIOfferData {
  return {
    status: offer.status,
    footerData: getFooterData(offer, locale),
    heroSectionData: getHeroSectionData(offer, locale),
    requirementsSectionData: getRequirementsSectionData(offer, locale),
    howSectionData: getHowSectionData(offer, locale),
    whenSectionData: getWhenSectionData(offer, locale),
    whereSectionData: getWhereSectionData(offer, locale),
    teamSectionData: getTeamSectionData(offer, locale),
    skillsSectionData: getSkillsSectionData(offer, locale),
    offerSectionData: getOfferSectionData(offer, locale),
    faqsSectionData: getFaqsSectionData(offer, locale),
    bottombarData: getOfferBottombarData(offer, locale)
  };
}

/**
 * Get the title of an `offer`
 *
 * @param offer - (server-side model) to get the title from
 * @param locale - in which `Multilingual` server-side data will be retrieved
 * @returns  The offer title
 */
export function getOfferTitle(
  offer: Offer | OfferSummary,
  locale?: string
): string {
  return getTranslatedText(offer.position, locale);
}

/**
 * Get the head image of an `offer`
 *
 * @param offer - (server-side model) to get the title from
 * @param origin - base URL for the default image link
 * @returns  The offer title
 */
export function getOfferHeadImage(
  offer: Offer,
  origin: string
): string | undefined {
  return (
    offer.company?.companyHiring?.headImage?.url ??
    `${origin}${getPublicPath('/images/jobOffer/companyPhoto.jpg')}`
  );
}

/**
 * Get the description of an `offer`
 *
 * @param offer - (server-side model) to get the title from
 * @param locale - in which `Multilingual` server-side data will be retrieved
 * @returns  The offer title
 */
export function getOfferDescription(offer: Offer, locale?: string): string {
  return getTranslatedText(offer.introduction, locale);
}

/**
 * Get the full name of the scout responsible of the given `offer`
 *
 * @param offer - (server-side model) to get the scout's full name from
 * @returns The scout's full name
 */
export function getOfferScoutFullName(offer: Offer): string {
  if (!offer?.scout) {
    return '';
  }

  return `${offer.scout?.firstName} ${offer.scout?.lastName}`;
}

/**
 * Get the canonical `offer` URL for a given `origin` and `locale`
 *
 * @param origin - base URL for the canonical link
 * @param offer - (server-side model) to get the title from
 * @param locale - in which `Multilingual` server-side data will be retrieved
 * @returns  The offer canonical URL
 */
export function getCanonicalUrl(
  origin: string,
  offer: Offer,
  locale?: string
): string {
  return `${origin}/${locale}${getAppUrl(
    APP_URLS.jobOffer,
    locale
  )}${getOfferUrl(offer, locale)}`;
}

/**
 * Get the `offer` URL for a given `locale`
 *

 * @param offer - (server-side model) to get the title from
 * @param locale - in which `Multilingual` server-side data will be retrieved
 * @returns  The offer  URL
 */
export function getOfferUrl(
  offer: Offer | OfferSummary,
  locale?: string
): string {
  const slug = getTranslatedText(offer.slug, locale);

  return `/${offer.id}/${slug}`;
}

/**
 * Get the TypeForm URL to apply to an `offer`
 *
 * @param offer - (server-side model) to get the TypeForm URL from
 * @param locale - in which `Multilingual` we will generate the URL
 * @returns  The TypeForm URL for this `offer`
 */
export function getTypeFormURL(offer: Offer, locale?: string): string {
  // TODO: this is bullshit! But temporary bullshit… ¯\_(ツ)_/¯
  const typeforms: { [key: number]: string } = {
    64: 'BWIPLf'
  };

  const offerName = `${encodeURIComponent(
    getTranslatedText(offer.position, locale)
  )} at ${offer.company?.name}`;

  return `https://getmanfred.typeform.com/to/${typeforms[offer.id] ?? 'GDvgX5'}?
	source=asgard&
	apply=${encodeURIComponent(offer.internalCode)}&
	offer=${offerName}&
	scoutname=${encodeURIComponent(getOfferScoutFullName(offer))}&
	scoutmail=${encodeURIComponent(offer.scout?.email || '')}`;
}

/**
 * Get equity range
 *
 * @param offer - (server-side model) to get the equity info from
 * @param locale - in which `Multilingual` we will get the info
 * @returns the equity info for the offer
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getEquityInfo(offer: Offer, locale?: string): string {
  const equity = [
    offer.equityInf?.toLocaleString(locale),
    offer.equitySup?.toLocaleString(locale)
  ]
    .filter(Boolean)
    .join('-');

  return equity ? equity : '';
}

/**
 * Get the badges UI items info for a given `offer`
 *
 * @param offer - (server-side model) to get the badges from
 * @param locale - in which `Multilingual` we will get the info
 * @returns the list of badges for the offer
 */
export function getOfferBadges(
  offer: Offer | OfferSummary,
  locale?: string,
  addEquity?: boolean
): UIOfferBadge[] {
  const badges: UIOfferBadge[] = offer.noStack
    ? [{ id: 'badge1', item: 'No-stack', highlight: true }]
    : [];

  const equityInf = (<Offer>offer).equityInf;
  const equitySup = (<Offer>offer).equitySup;

  if (addEquity && (equityInf || equitySup)) {
    let text = `${equityInf?.toLocaleString(
      locale
    )}% - ${equitySup?.toLocaleString(locale)}%`;

    if (equityInf === equitySup) {
      text = `${equitySup?.toLocaleString(locale)}%`;
    }

    if (equityInf && !equitySup) {
      text = `${i18n
        ?.t('hero.from', { ns: 'job-offer', lng: locale })
        .toLowerCase()} ${equityInf?.toLocaleString(locale)}%`;
    }

    if (!equityInf && equitySup) {
      text = `${i18n
        ?.t('hero.up_to', { ns: 'job-offer' })
        .toLowerCase()} ${equitySup?.toLocaleString(locale)}%`;
    }

    badges.push({
      id: 'badgeequity',
      item: `${getTranslation('offer.equity', 'job-offer', locale)} ${text}`
    });
  }

  return [
    ...badges,
    ...(offer.highlights ? offer.highlights : [])
      .filter((badge) =>
        offer.languages?.length > 1
          ? locale && !!badge[locale.toUpperCase()]
          : true
      )
      .map((h, i) => {
        return {
          id: `${i}`,
          item: getTranslatedText(h, locale),
          highlight: false
        };
      })
  ];
}

/**
 * Get the perks UI items info for a given `offer`
 *
 * @param offer - (server-side model) to get the perks from
 * @param locale - in which `Multilingual` we will get the info
 * @returns the list of perks for the offer
 */
export function getOfferPerks(
  offer: Offer,
  locale?: string
): UIOfferPerk[] | void[] {
  if (!offer.perks) {
    return [];
  }

  return offer.perks?.map((perk) => ({
    perk: getTranslatedText(perk.name, locale),
    icon: perk.icon?.url,
    desc: getTranslatedText(perk.description, locale)
  }));
}

/**
 * Get the techs UI items info for a given tech `section` of an `offer`
 *
 * @param offer - (server-side model) to get the techs from
 * @param section - `OFFER_TECH_SKILLS_SECTION` of the Techs
 * @param locale - in which `Multilingual` we will get the info
 * @returns the list of techs for the `section` of the given `offer`
 */
export function getOfferTechsBySection(
  offer: Offer,
  section: OFFER_TECH_SKILLS_SECTION,
  locale?: string
): UIOfferSkill[] {
  if (!offer.techs) {
    return [];
  }

  return offer.techs
    .filter((t) => t.OfferTech.section === section)
    .sort((a, b) =>
      (a.OfferTech?.order || 0) > (b.OfferTech?.order || 0) ? 1 : -1
    )
    .map((t) => {
      return {
        skill: t.name,
        icon: t.icon?.url,
        level: UITechSkillsLevel[<OFFER_TECH_SKILLS_LEVEL>t.OfferTech.level],
        desc: getTranslatedText(t.info, locale)
      };
    });
}

/**
 * Get the soft skills UI items info for a given soft skill `section` of an `offer`
 *
 * @param offer - (server-side model) to get the soft skills from
 * @param section - `OFFER_SOFT_SKILLS_SECTION` of the Soft Skills
 * @param locale - in which `Multilingual` we will get the info
 * @returns the list of soft skills for the `section` of the given `offer`
 */
export function getOfferSoftSkillsBySection(
  offer: Offer,
  section: OFFER_SOFT_SKILLS_SECTION,
  locale?: string
): UIOfferSkill[] {
  if (!offer.softSkills) {
    return [];
  }

  return offer.softSkills
    .filter((t) => t.OfferSoftSkill.section === section)
    .sort((a, b) =>
      (a.OfferSoftSkill?.order || 0) > (b.OfferSoftSkill?.order || 0) ? 1 : -1
    )
    .map((t) => {
      return {
        skill: getTranslatedText(t.name, locale),
        icon: t.icon?.url,
        desc: getTranslatedText(
          t.OfferSoftSkill.description || t.description,
          locale
        )
      };
    });
}
/**
 * Get of `offer` is considered closed
 *
 * @param offer - (server-side model) to check
 * @returns if the offer is closed or not
 */
export function isOfferClosed(offer: Offer | OfferSummary): boolean {
  return OFFER_STATUS_CLOSED.includes(offer.status);
}

function cleanLocations(location: string): string {
  return location.replace(', España', '').replace(', Spain', '');
}

const LOCATIONS_SEPARATOR = ', ';

export function getCityStringFromUIPlacesAutocompleteItem(
  locations?: Location[],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _locale?: string
): string {
  return (
    locations
      ?.map((location) => location?.displayName || '')
      .filter(Boolean)
      .map(cleanLocations)
      .join(LOCATIONS_SEPARATOR) || ''
  );
}

export function getCityStringFromLocations(
  locations?: LocationDB[],
  locale?: string
): string {
  return (
    locations
      ?.map((location) => getTranslatedText(location.data?.displayName, locale))
      .filter(Boolean)
      .map(cleanLocations)
      .join(LOCATIONS_SEPARATOR) || ''
  );
}

export type UILocationsSummary = {
  trimmedCount: number;
  trimmedCities: string[];
  trimmedCitiesStr: string;
  extraCount: number;
  extraCities: string[];
  extraCitiesStr: string;
};

export function getSummaryFromLocations(
  locations?: LocationDB[],
  locale?: string
): UILocationsSummary {
  const MAX_CITIES = 4;

  const allLocations =
    locations
      ?.map((location) => getTranslatedText(location.data?.displayName, locale))
      .map(cleanLocations)
      .filter(Boolean) || [];

  const allLocationsCount = allLocations.length;

  const trimmedCities = allLocations.slice(0, MAX_CITIES);
  const trimmedCount = trimmedCities.length;
  const trimmedCitiesStr =
    trimmedCities.join(` ${i18n?.t('or', { ns: 'common' }) || ', '} `) || '';

  const extraCities = allLocations.slice(MAX_CITIES, allLocationsCount - 1);
  const extraCount = extraCities.length;
  const extraCitiesStr =
    extraCities.join(` ${i18n?.t('or', { ns: 'common' }) || ', '} `) || '';

  return {
    trimmedCount,
    trimmedCities,
    trimmedCitiesStr,
    extraCount,
    extraCities,
    extraCitiesStr
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getFooterData(offer: Offer, locale?: string): UIOfferFooter {
  return {
    id: offer.id,
    logo: offer.company?.logoDark?.url ?? offer.company?.logo?.url,
    company: offer.company?.name,
    scout: getOfferScoutFullName(offer),
    scoutAvatar: offer.scout?.avatar?.url,
    scoutUrl: offer.scout?.email || '',
    typeFormURL: getTypeFormURL(offer),
    closed: isOfferClosed(offer),
    internalCode: offer.internalCode
  };
}

function getHeroSectionData(offer: Offer, locale?: string): UIOfferHeroSection {
  const badges = getOfferBadges(offer, locale, true);
  const {
    company,
    lowerSalaryRange,
    higherSalaryRange,
    offerCurrency,
    remotePercentage,
    locations,
    introduction
  } = offer;

  const bonus = getKSalary(offer.bonusSup)?.toLocaleString(locale);

  return {
    companyName: company?.name,
    companyLogo: company?.logo?.url,
    companyLogoDark: company?.logoDark?.url ?? company?.logo?.url,
    companyUrl: company?.web,
    companyArea: 'Área de desarrollo y Seguridad',
    companyPrimaryColor: company?.companyHiring?.primaryColor,
    companySecondaryColor: company?.companyHiring?.secondaryColor,
    companyPhoto:
      company?.companyHiring?.headImage?.url ??
      getPublicPath('/images/jobOffer/companyPhoto.jpg'),
    companyPhotoAlt:
      getTranslatedText(company?.companyHiring?.headImage?.alt) ??
      company?.name,
    companyManagementLogo: getPublicPath('/images/logo-manfred.svg'),
    companyManagementLogoAlt: 'Manfred',
    companyScout: getOfferScoutFullName(offer),
    companyScoutUrl: `${offer.scout?.email}`,
    companyScoutAvatar: offer.scout?.avatar?.url,
    jobTitle: getOfferTitle(offer, locale),
    salaryMin: getKSalary(lowerSalaryRange),
    salaryMax: getKSalary(higherSalaryRange),
    currency: offerCurrency,
    jobLocationsSummary: getSummaryFromLocations(locations, locale),
    jobRemote: remotePercentage,
    jobLocationCombined: remotePercentage !== null && remotePercentage !== 0,
    badges,
    jobDescription: getTranslatedText(introduction, locale),
    companyManagementLogoDark: getPublicPath(
      '/images/logo-manfred-inverse.svg'
    ),
    closed: isOfferClosed(offer),
    closedDate: offer.lastStatusChange,
    bonus: offer.bonusSup ? `${bonus}` : undefined
  };
}

function getRequirementsSectionData(
  offer: Offer,
  locale?: string
): UIOfferRequirementsSection {
  const { company } = offer;

  const responsabilities = offer.responsibilities
    .filter((resp) =>
      offer.languages?.length > 1
        ? locale && !!resp[locale.toUpperCase()]
        : true
    )
    .map((r, i) => ({
      id: `${i}`,
      item: getTranslatedText(r, locale)
    }));

  return {
    companyLogo: company?.logo.url,
    companyName: company?.name,
    evolution: {
      month1: getTranslatedText(
        offer.expectations?.expectations.inOneMonth,
        locale
      ),
      month3: getTranslatedText(
        offer.expectations?.expectations.inThreeMonths,
        locale
      ),
      month6: getTranslatedText(
        offer.expectations?.expectations.inSixMonths,
        locale
      )
    },
    requirementsIntro: getTranslatedText(offer.whatWillYouDo, locale),
    responsabilities
  };
}

function getHowSectionData(offer: Offer, locale?: string): UIOfferHowSection {
  return {
    howIntro: getTranslatedText(offer.howWillYouDo, locale),
    howItems: [
      {
        id: 'asd1',
        title: 'lorem ipsum dolor',
        text: 'Qui sequi adipisci cum similique at sit. Quia excepturi amet tenetur reiciendis. Ullam voluptas rerum fuga incidunt iusto voluptas.'
      },
      {
        id: 'asd2',
        title: 'lorem ipsum dolor',
        text: 'Qui sequi adipisci cum similique at sit. Quia excepturi amet tenetur reiciendis. Ullam voluptas rerum fuga incidunt iusto voluptas.'
      },
      {
        id: 'asd3',
        title: 'lorem ipsum dolor',
        text: 'Qui sequi adipisci cum similique at sit. Quia excepturi amet tenetur reiciendis. Ullam voluptas rerum fuga incidunt iusto voluptas.'
      },
      {
        id: 'asd4',
        title: 'lorem ipsum dolor',
        text: 'Qui sequi adipisci cum similique at sit. Quia excepturi amet tenetur reiciendis. Ullam voluptas rerum fuga incidunt iusto voluptas.'
      },
      {
        id: 'asd5',
        title: 'lorem ipsum dolor',
        text: 'Qui sequi adipisci cum similique at sit. Quia excepturi amet tenetur reiciendis. Ullam voluptas rerum fuga incidunt iusto voluptas.'
      },
      {
        id: 'asd6',
        title: 'lorem ipsum dolor',
        text: 'Qui sequi adipisci cum similique at sit. Quia excepturi amet tenetur reiciendis. Ullam voluptas rerum fuga incidunt iusto voluptas.'
      }
    ]
  };
}

function getWhenSectionData(offer: Offer, locale?: string): UIOfferWhenSection {
  const highlights = getJobScheduleHighlights(offer, locale);
  const schedules = getJobSchedules(offer, locale);

  return {
    whenIntro: getTranslatedText(offer.whenWillDoIt, locale),
    highlights,
    schedules,

    watchIntro: getTranslatedText(offer.watches?.introduction, locale),
    watchFaqs: (offer.watches?.faq ?? []).map((w) => ({
      a: getTranslatedText(w.answer, locale),
      q: getTranslatedText(w.question, locale)
    }))
  };
}

function getWhereSectionData(
  offer: Offer,
  locale?: string
): UIOfferWhereSection {
  return {
    jobLocationsSummary: getSummaryFromLocations(offer.locations, locale),
    jobRemote: offer.remotePercentage,
    jobInfo: getTranslatedText(offer.whereWillDoIt, locale),
    companyLogo: offer.company?.logo.url,
    companyAddress:
      'Sor Joaquina 6<br />(Zona de San Roque de Afuera)<br />15011 <strong>A Coruña</strong> (SPAIN)',
    googleMaps: 'https://goo.gl/maps/C8W6xBCdLtXybSza8',
    spacesSlides: [
      {
        img: getPublicPath('/images/jobOffer/spaces01.jpg'),
        caption:
          'Que no falte ni material para trabajar ni pequeños detalles que hagan más ameno el día laboral con bebida y comida sana.'
      },
      {
        img: getPublicPath('/images/jobOffer/spaces02.jpg'),
        caption:
          'La comodidad no se riñe con el trabajo. Hemos seleccionado el mobiliario para una modularidad de espacios y movimientos.'
      },
      {
        img: getPublicPath('/images/jobOffer/spaces03.jpg'),
        caption:
          'Espacios multidisciplinares con amplias mesas para reflexionar, brainsormear, designthinkear y lo que haga falta.'
      },
      {
        img: getPublicPath('/images/jobOffer/spaces04.jpg'),
        caption:
          'Porque no todo ha de ser cemento y tecnología, nos gustar rodearnos de vegetación y tener contacto con la naturaleza.'
      },
      {
        img: getPublicPath('/images/jobOffer/spaces05.jpg'),
        caption:
          'De vez en cuando nos apetece reunirnos y montar una buena comilona con nuestros productos da terra galega en la terraza. :)'
      }
    ],
    officePerks: [
      {
        type: UI_WHERE_PERKS.diningRoom,
        text: 'Tiene un menú del día bueno y barato en nuestra cantina'
      },
      {
        type: UI_WHERE_PERKS.transport,
        text: 'La LÍNEA 2 de bus para en la propia puerta'
      }
    ]
  };
}

function getTeamSectionData(offer: Offer, locale?: string): UIOfferTeamSection {
  return {
    teamImage: getPublicPath('/images/jobOffer/team.jpg'),
    teamImageCaption:
      'El equipo de Corunet está compuesto por bravos atletas y testosterona a go-gó, pero son majos y velarán por ti.',
    teamInfo: getTranslatedText(offer.whoWillDoItWith, locale),
    membersInfo:
      '<p>En Corunet tienen Líderes de Equipo para ayudarte a sacar lo mejor de ti mismo y desplegar todo tu potencial... Su equipo <strong>‘Delta force’</strong> de Principals, está formado por:</p>',
    members: [
      {
        fullName: 'Ana Ramírez Gurrutxaga',
        desc: 'Fundadora de Corunet, además de ser una experta Backend-Developer, es una excelente jefa de equipo.',
        img: getPublicPath('/images/jobOffer/teamMember01.jpg')
      },
      {
        fullName: 'Miguel Díaz Poblete',
        desc: 'Le sobra experiencia en Couch DB por todas partes. Y además, siempre está de buen humor. Además de un gran profesional es como un padre para todos nosotros.',
        img: getPublicPath('/images/jobOffer/teamMember02.jpg')
      },
      {
        fullName: 'Lucía Calvo Matesanz',
        desc: 'La problem-solver del equipo. Una persona con la que siempre puedes contar. Un valor seguro.',
        img: getPublicPath('/images/jobOffer/teamMember03.jpg')
      },
      {
        fullName: 'Jeffrey McPherson Dougall',
        desc: 'Full-stack developer de Corunet con amplísima experiencia en Microsoft Iberia y recién aterrizado será un gran aliado tuyo en las labores diarias',
        img: getPublicPath('/images/jobOffer/teamMember04.jpg')
      },
      {
        fullName: '“Pato” McPherson Dougall',
        desc: 'Otro Full-stack developer que se ha incorporado junto con su hermano Jeffrey. Esto es una familia numerosa. :)',
        img: getPublicPath('/images/jobOffer/teamMember05.jpg')
      },
      {
        fullName: 'Ana Ramírez Gurrutxaga',
        desc: 'Fundadora de Corunet, además de ser una experta Backend-Developer, es una excelente jefa de equipo.',
        img: getPublicPath('/images/jobOffer/teamMember01.jpg')
      },
      {
        fullName: 'Miguel Díaz Poblete',
        desc: 'Le sobra experiencia en Couch DB por todas partes. Y además, siempre está de buen humor. Además de un gran profesional es como un padre para todos nosotros.',
        img: getPublicPath('/images/jobOffer/teamMember02.jpg')
      },
      {
        fullName: 'Lucía Calvo Matesanz',
        desc: 'La problem-solver del equipo. Una persona con la que siempre puedes contar. Un valor seguro.',
        img: getPublicPath('/images/jobOffer/teamMember03.jpg')
      }
    ]
  };
}

function getSkillsSectionData(
  offer: Offer,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  locale?: string
): UIOfferSkillsSection {
  return {
    intro: getTranslatedText(offer.thatTheyAskFor, locale),
    noStack: offer.noStack,
    minLanguages:
      offer.minLanguages
        ?.map((minLang) => ({
          name: getTranslatedText(minLang.name, locale),
          level:
            CommonDtoLevelToLanguageLevelDict[
              minLang.OfferMinLanguageModel?.level || LanguageLevel.Undefined
            ]
        }))
        .filter((minLang) => minLang.level !== 'not-defined') || [],
    skills: {
      must: getOfferTechsBySection(
        offer,
        OFFER_TECH_SKILLS_SECTION.MUST,
        locale
      ),
      nice: getOfferTechsBySection(
        offer,
        OFFER_TECH_SKILLS_SECTION.COULD,
        locale
      ),
      extra: getOfferTechsBySection(
        offer,
        OFFER_TECH_SKILLS_SECTION.EXTRA,
        locale
      )
    },
    softSkills: {
      must: getOfferSoftSkillsBySection(
        offer,
        OFFER_SOFT_SKILLS_SECTION.MUST,
        locale
      ),
      nice: getOfferSoftSkillsBySection(
        offer,
        OFFER_SOFT_SKILLS_SECTION.COULD,
        locale
      ),
      extra: getOfferSoftSkillsBySection(
        offer,
        OFFER_SOFT_SKILLS_SECTION.EXTRA,
        locale
      )
    }
  };
}

function getOfferSectionData(
  offer: Offer,
  locale?: string
): UIOfferOfferSection {
  const bonus = getKSalary(offer.bonusSup)?.toLocaleString(locale);

  return {
    companyName: offer.company?.name || '',
    offerCode: offer.internalCode,
    offer: {
      salaryMin: getKSalary(offer.lowerSalaryRange),
      salaryMax: getKSalary(offer.higherSalaryRange),
      currency: offer.offerCurrency,
      salaryExtra: getEquityInfo(offer, locale),
      bonus: offer.bonusSup ? `${bonus}` : undefined,
      bonusInfo: 'offer.bonusInfo',
      contract: getTranslatedText(offer.typeOfContract, locale),
      hours: offer.flextime,
      remote: offer.remotePercentage ? `${offer.remotePercentage}%` : undefined,
      equityInf: offer.equityInf
        ? offer.equityInf.toLocaleString(locale)
        : null,
      equitySup: offer.equitySup ? offer.equitySup.toLocaleString(locale) : null
    },
    offerInfo: getTranslatedText(offer.whatOffering, locale),
    perks: {
      compensation: [
        {
          perk: 'Cheques restaurant',
          icon: getPublicPath('/images/icons/restaurant-voucher.svg')
        },
        {
          perk: 'Transporte',
          icon: getPublicPath('/images/icons/bus.svg'),
          desc: 'Bono transporte'
        }
      ],
      extra: getOfferPerks(offer, locale)
    }
  };
}

function getFaqsSectionData(offer: Offer, locale?: string): UIOfferFaqsSection {
  return {
    faqs: offer.faq
      .filter((faq) =>
        offer.languages?.length > 1
          ? locale &&
            !!faq.answer[locale.toUpperCase()] &&
            !!faq.question[locale.toUpperCase()]
          : true
      )
      .map((f) => ({
        a: getTranslatedText(f.answer, locale),
        q: getTranslatedText(f.question, locale)
      }))
  };
}

function getOfferBottombarData(
  offer: Offer,
  locale: string = 'es'
): UIOfferBottomBar {
  const {
    company,
    lowerSalaryRange,
    higherSalaryRange,
    offerCurrency,
    remotePercentage,
    locations
  } = offer;

  const bonus = getKSalary(offer.bonusSup)?.toLocaleString(locale);

  return {
    id: offer.id,
    companyName: company?.name,
    companyLogo: company?.logoDark?.url ?? company?.logo?.url,
    typeFormUrl: getTypeFormURL(offer, locale),
    jobTitle: getOfferTitle(offer, locale),
    salaryMin: getKSalary(lowerSalaryRange),
    salaryMax: getKSalary(higherSalaryRange),
    currency: offerCurrency,
    jobLocationsSummary: getSummaryFromLocations(locations, locale),
    jobRemote: remotePercentage,
    jobLocationCombined: remotePercentage !== null && remotePercentage !== 0,
    hasEquity: Boolean(offer.equityInf) || Boolean(offer.equitySup),
    hasBonus: Boolean(offer.bonusSup),
    closed: isOfferClosed(offer),
    equityInf: offer.equityInf ? offer.equityInf?.toLocaleString(locale) : null,
    equitySup: offer.equitySup ? offer.equitySup?.toLocaleString(locale) : null,
    bonus: offer.bonusSup ? `${bonus}` : null,
    internalCode: offer.internalCode
  };
}

function getJobSchedules(offer: Offer, locale?: string): UIOfferWhenSchedule[] {
  return (
    offer.jobSchedules?.schedules?.map(({ name, text, bars }) => {
      return {
        name: getTranslatedText(name, locale),
        text: getTranslatedText(text, locale),
        bars: bars?.map(({ inOutFlexibleMinutes, ranges, title, subtitle }) => {
          const allRanges: UIOfferRanges[] = [];

          if (inOutFlexibleMinutes > 0) {
            allRanges.push({
              startTime: '',
              endTime: '',
              totalTime: +(inOutFlexibleMinutes / 60).toFixed(2),
              flexibleTime: true,
              freeTime: false
            });
          }

          allRanges.push(
            ...(ranges || []).map(({ startTime, endTime, freeTime }) => {
              return {
                startTime,
                endTime,
                totalTime: +(
                  (getMinutesFromStringTime(endTime) -
                    getMinutesFromStringTime(startTime)) /
                  60
                ).toFixed(2),
                flexibleTime: false,
                freeTime: freeTime
              };
            })
          );

          if (inOutFlexibleMinutes > 0) {
            const previousRangeEndTimeInMinutes = getMinutesFromStringTime(
              allRanges[allRanges.length - 1].endTime
            );

            const firstRangeStartTimeInMinutes = getMinutesFromStringTime(
              allRanges[1].startTime
            );

            allRanges[0].startTime = getStringTimeFromMinutes(
              firstRangeStartTimeInMinutes - inOutFlexibleMinutes
            );

            allRanges[0].endTime = allRanges[1].startTime;

            allRanges.push({
              startTime: allRanges[allRanges.length - 1].endTime,
              endTime: getStringTimeFromMinutes(
                previousRangeEndTimeInMinutes + inOutFlexibleMinutes
              ),
              totalTime: +(inOutFlexibleMinutes / 60).toFixed(2),
              flexibleTime: true,
              freeTime: false
            });
          }

          return {
            title: getTranslatedText(title, locale),
            subtitle: getTranslatedText(subtitle, locale),
            inOutFlexibleMinutes,

            // TODO: handle caption
            caption: '',
            ranges: allRanges
          };
        })
      };
    }) || []
  );
}

function getJobScheduleHighlights(
  offer: Offer,
  locale?: string
): {
  title: string;
  value: string;
  icon: string;
  tooltip?: string;
}[] {
  const highlights: {
    title: string;
    value: string;
    icon: string;
    tooltip?: string;
  }[] = [];

  if (offer.jobSchedules?.fullTime) {
    highlights.push({
      title: getTranslation('when.highlights.workday', 'job-offer', locale),
      value: getTranslation(
        'when.highlights.workdayFulltime',
        'job-offer',
        locale
      ),
      icon: getPublicPath('/images/icons/calendar.svg')
    });
  }

  if (
    offer.jobSchedules?.holidaysPerYear !== null &&
    typeof offer.jobSchedules?.holidaysPerYear !== 'undefined'
  ) {
    highlights.push({
      title: getTranslation('when.highlights.holidays', 'job-offer', locale),
      value:
        offer.jobSchedules?.holidaysPerYear === 0
          ? getTranslation('when.highlights.unlimited', 'job-offer', locale)
          : getTranslation('when.highlights.days', 'job-offer', locale, {
              days: offer.jobSchedules?.holidaysPerYear
            }),
      icon: getPublicPath('/images/icons/relax.svg')
    });
  }

  if (offer.jobSchedules?.flextime) {
    highlights.push({
      title: getTranslation('when.highlights.jobSchedule', 'job-offer', locale),
      value: getTranslation('when.highlights.flexible', 'job-offer', locale),
      icon: getPublicPath('/images/icons/clock.svg')
    });
  }

  if (offer.jobSchedules?.continuousWorkDayFriday) {
    highlights.push({
      title: getTranslation(
        'when.highlights.continuousWorkday',
        'job-offer',
        locale
      ),
      value: getTranslation('when.highlights.onFriday', 'job-offer', locale),
      icon: getPublicPath('/images/icons/important-day.svg')
    });
  }

  if (offer.jobSchedules?.continuousWorkDaySummer) {
    highlights.push({
      title: getTranslation(
        'when.highlights.continuousWorkday',
        'job-offer',
        locale
      ),
      value: getTranslation('when.highlights.onSummer', 'job-offer', locale),
      icon: getPublicPath('/images/icons/holidays.svg')
    });
  }

  return highlights;
}
